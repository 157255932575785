<template>
  <div class="bg-white h-screen">
    <trac-loading v-if="isLoading"></trac-loading>
    <div class="w-full flex flex-wrap">
      <!-- Login Section -->
      <div class="w-full flex flex-col">
        <div
          class="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-24 lg:px-32"
        >
          <div
            class="md:h-screen flex justify-center items-center"
            v-if="!email && !reset_token"
          >
            <div class="bg-white rounded-lg w-full md:w-2/5 md:px-16 py-16">
              <form
                class="flex flex-col pt-3 md:pt-8"
                @submit.prevent="requestPasswordResetLink"
              >
                <div class="flex font-bold justify-center">
                  <img
                    class="h-10 w-10 rounded-full"
                    src="../assets/logo.png"
                  />
                </div>
                <h2
                  class="text-2xl text-blue-900 text-center text-gray-700 mb-4"
                >
                  Traction Admin
                </h2>
                <p class="text-center text-sm">
                  Please enter your email to continue
                </p>
                <div
                  class="input-div border-b-2 relative grid my-5 py-1 focus:outline-none"
                  style="grid-template-columns: 7% 93%"
                >
                  <div class="i">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-person-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                      <path
                        fill-rule="evenodd"
                        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                      />
                    </svg>
                  </div>
                  <div class="div">
                    <input
                      placeholder="Email Address/Username"
                      type="text"
                      class="absolute w-full h-full py-2 px-3 outline-none inset-0 text-gray-700"
                      style="background: none"
                      v-model="loginDetails.email"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  class="w-full py-2 rounded-full bg-blue-900 text-gray-100 focus:outline-none"
                >
                  Submit
                </button>
                <button
                  @click="
                    $router.push({
                      name: 'Login',
                    })
                  "
                  type="button"
                  class="w-full py-2 bg-transparent mt-3 text-blue-500 font-semibold text-sm focus:outline-none"
                >
                  &laquo; Back to Login
                </button>
              </form>
              <div class="text-center py-5 text-red-800" v-if="error">
                {{ error }}
              </div>
              <div class="text-center text-green-600 py-5" v-else>
                {{ responseMessage }}
              </div>
            </div>
          </div>
          <div class="md:h-screen flex justify-center items-center" v-else>
            <div class="bg-white rounded-lg w-full md:w-2/5 md:px-16 py-16">
              <div class="flex my-3">
                <div class="">
                  <button
                    class="bg-transparent text-black py-3 px-3 rounded-md"
                    @click="goBack"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      class="bi bi-arrow-left-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <form
                class="flex flex-col pt-3 md:pt-8"
                @submit.prevent="resetPassword"
              >
                <div class="flex font-bold justify-center">
                  <img
                    class="h-10 w-10 rounded-full"
                    src="../assets/logo.png"
                  />
                </div>
                <h2
                  class="text-2xl text-blue-900 text-center text-gray-700 mb-4"
                >
                  Traction Admin
                </h2>
                <p class="text-center text-sm">
                  Hi {{ email }}, please enter your new password to continue
                </p>
                <p class="text-xs mb-5">
                  Passwords should be at least 8 characters long, must use one
                  Uppercase letter, and one lower case letter, one number, one
                  special symbol (!, *, &, etc.)
                </p>
                <label>Enter Password</label>
                <div
                  class="input-div border-b-2 relative grid py-1 focus:outline-none"
                  style="grid-template-columns: 7% 86% 7%"
                >
                  <div class="i">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-lock-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"
                      />
                    </svg>
                  </div>
                  <div class="div">
                    <input
                      placeholder="Password"
                      type="password"
                      v-model="loginDetails.password"
                      class="absolute w-full h-full py-2 px-3 outline-none inset-0 text-gray-700"
                      style="background: none"
                      id="password"
                    />
                  </div>
                  <div
                    class="i cursor-pointer"
                    style="color: #000"
                    @click="togglePassword('password')"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-eye"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                      />
                      <path
                        d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  class="w-full h-1 mb-5"
                  :class="
                    checkPassword(this.loginDetails.password)
                      ? 'bg-green-500'
                      : 'bg-red-600'
                  "
                ></div>
                <label>Re-enter Password</label>
                <div
                  class="input-div border-b-2 relative grid py-1 focus:outline-none"
                  style="grid-template-columns: 7% 86% 7%"
                >
                  <div class="i">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-lock-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"
                      />
                    </svg>
                  </div>
                  <div class="div">
                    <input
                      placeholder="Confirm Password"
                      type="password"
                      v-model="loginDetails.cpassword"
                      class="absolute w-full h-full py-2 px-3 outline-none inset-0 text-gray-700"
                      style="background: none"
                      id="cpassword"
                    />
                  </div>
                  <div
                    class="i cursor-pointer"
                    style="color: #000"
                    @click="togglePassword('cpassword')"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-eye"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                      />
                      <path
                        d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  class="w-full h-1 mb-5"
                  :class="
                    checkPassword(this.loginDetails.cpassword)
                      ? 'bg-green-500'
                      : 'bg-red-600'
                  "
                ></div>
                <button
                  type="submit"
                  class="w-full py-3 rounded-full bg-blue-900 text-gray-100 focus:outline-none"
                >
                  Submit
                </button>
              </form>
              <div class="text-center py-5 text-red-800" v-if="error">
                {{ error }}
              </div>
              <div class="text-center py-5" v-else>
                {{ responseMessage }}
              </div>
            </div>
          </div>
          <div class="text-center pt-12 pb-12">
            <p class="text-xs">
              TractionApps admin platform for managing payments for clientele
              and other logs.
            </p>
          </div>
          <div
            v-if="promptDiv"
            class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
          >
            <div
              class="w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 text-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                class="bi bi-check2-circle text-green-500 mx-auto"
                viewBox="0 0 16 16"
              >
                <path
                  d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"
                />
                <path
                  d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"
                />
              </svg>
              <p class="text-sm">
                {{ promptMessage }}
              </p>
              <button
                @click="promptDiv = false"
                class="float-right uppercase shadow mx-auto hover:bg-red-500 bg-red-600 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-2 rounded"
                style="width: inherit"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SAVE_LOCAL_DB_DATA } from "../browser-db-config/localStorage";

export default {
  data() {
    return {
      isLoading: false,
      loginDetails: {
        email: "",
        password: "",
        cpassword: "",
      },
      responseMessage: "",
      error: null,
      email: this.$route.query.email || "",
      reset_token: this.$route.query.reset_id || "",
      promptDiv: false,
      promptMessage: "",
    };
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "ResetPassword",
        params: {
          reset_id: "",
        },
      });
      this.email = "";
      this.reset_token = "";
    },
    async requestPasswordResetLink() {
      this.error = null;
      this.isLoading = true;
      // const res = await this.$store.dispatch(
      //   "REQUEST_USER_RESET_LINK",
      //   this.loginDetails
      // );
      // console.log(res.status);
      // if (res.status) {
      //   this.isLoading = false;
      //   this.responseMessage = res.data.message;
      // } else {
      //   this.error = res.error;
      //   this.responseMessage = res.message;
      //   alert(res.message || "Error: Netowrk error occured.");
      // }
      // this.isLoading = false;
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("RESET_PASSWORD_ADMIN_NO_AUTH", {
          email: this.loginDetails.email,
        });
        if (res.status == true) {
          this.isLoading = false;
          this.promptMessage = res.message;
          this.loginDetails.email = "";
          this.promptDiv = true;
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
          // $router.go(-1);
        }
      } catch (error) {}

      this.isLoading = false;
    },

    async resetPassword() {
      if (this.loginDetails.cpassword === this.loginDetails.password) {
        if (this.checkPassword(this.loginDetails.password)) {
          this.error = null;
          this.isLoading = true;
          let payload = {
            // email: this.email,
            password: this.loginDetails.password,
            id: this.reset_token,
          };
          const res = await this.$store.dispatch("SET_USER_PASSWORD", payload);
          if (res.status) {
            this.isLoading = false;
            this.responseMessage = res.message;
            alert(res.message);
            this.$router.push({
              name: "Login",
            });
          } else {
            this.error = res.error;
            this.responseMessage = res.message;
            alert(res.message || "Error: Netowrk error occured.");
          }
          this.isLoading = false;
        } else {
          this.error = "Sorry. password must be more than 8 characters.";
        }
      } else {
        this.error = "Password does not match";
      }
    },

    checkPassword(password) {
      return new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})"
      ).test(password);
    },

    togglePassword(el) {
      var x = document.getElementById(el);
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    },
  },
};
</script>

<style>
.i {
  color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.i i {
  transition: 0.3s;
}

.input-div > div {
  position: relative;
  height: 45px;
}

.input-div > div > h5 {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  font-size: 18px;
  transition: 0.3s;
}

.input-div:before,
.input-div:after {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 0%;
  height: 2px;
  background-color: #38d39f;
  transition: 0.4s;
}

.input-div:before {
  right: 50%;
}

.input-div:after {
  left: 50%;
}

.input-div.focus:before,
.input-div.focus:after {
  width: 50%;
}

.input-div.focus > div > h5 {
  top: -5px;
  font-size: 15px;
}

.input-div.focus > .i > i {
  color: #38d39f;
}
</style>
